import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import Sticky from "react-stickynode";
import arrow from "../img/home/down-arrow.png";
import bluearrow from "../img/down-arrow-blue.svg";
import useAnalyticsEventTracker from "../components/analytics/useAnalyticsEventTracker";
import Avatar from "@mui/material/Avatar";
import { signOutReq } from "../api/api_services";
import { verifyLicenseStatus } from "../../src/utils/license-utils";
import DropDownMenu from "./Common/DropDownMenu";
import FireFlinkAsset from "../img/assets/fireflink-assets.svg";
import FireFlinkAssetWhite from "../img/assets/fireflink-assets-white.svg";
import GetDemoNavButton from "./Common/GetDemoNavBtn";
import SignInButton from "./Common/SignInButton";
import Ai from "../img/home/menu/platform/fireflink-ai.svg";
import WebAutomation from "../img/home/menu/platform/web-automation-testing.svg";
import MobileAutomation from "../img/home/menu/platform/mobile-automation.svg";
import ApiAutomation from "../img/home/menu/platform/api-testing.svg";
import ManualTest from "../img/home/menu/platform/manual-test-case-management.svg";
import Defect from "../img/home/menu/platform/defect-management.svg";
import UiTest from "../img/home/menu/platform/ui-performance-testing.svg";
import VisualTesting from "../img/home/menu/platform/visual-testing.svg";
import MobileLocator from "../img/home/menu/finder/mobile-locator-finder.svg";
import MsLocator from "../img/home/menu/finder/ms-dynamic-locator.svg";
import SalesForceLocator from "../img/home/menu/finder/sales-force-locator.svg";
import WebLocatorFinder from "../img/home/menu/finder/web-locator.svg";
import DomainAssets from "../img/home/menu/assets/domain-assets.svg";
import TemplateStore from "../img/home/menu/assets/template-store-assets.svg";
import PlatformAssets from "../img/home/menu/assets/platform-assets.svg";
import CloudLive from "../img/home/menu/cloud/cloud-live.svg";
import CloudMobile from "../img/home/menu/cloud/cloud-mobile.svg";
import CloudWeb from "../img/home/menu/cloud/cloud-web.svg";

import MsDynamics from "../img/home/menu/solutions/ms.svg";
import Oracle from "../img/home/menu/solutions/oracle.svg";
import PeopleSoft from "../img/home/menu/solutions/people-soft.svg";
import SalesForce from "../img/home/menu/solutions/salesforce.svg";
import SapAutomation from "../img/home/menu/solutions/sap.svg";
import ServiceNow from "../img/home/menu/solutions/servicenow.svg";
import WorkDayAutomation from "../img/home/menu/solutions/workday-seeklogo.svg";
import Academy from "../img/home/menu/resources/academy.svg";
import Blogs from "../img/home/menu/resources/blog.svg";
import CaseStudy from "../img/home/menu/resources/case-studies.svg";
import Documentation from "../img/home/menu/resources/documentation.svg";
import PreRequites from "../img/home/menu/resources/prerequisites.svg";
import ReleaseNotes from "../img/home/menu/resources/release-notes.svg";
import Support from "../img/home/menu/resources/support.svg";
import VideoTutorials from "../img/home/menu/resources/video-tutorials.svg";
import OrangeDownarrow from "../img/home/menu/orange-down-arrow.svg";
import { openDeepLink } from "../utils/openDeepLink";

const AssetsNavbar = (props) => {
  const [hoverProducts, setHoverProducts] = useState(false);
  const [hoverSolutions, setHoverSolutions] = useState(false);
  const [hoverResources, setHoverResources] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [isLoading, setIsloading] = useState(true);
  const [isHoveringPlatform, setIsHoveringPlatform] = useState(false);
  const [isHoveringFinder, setIsHoveringFinder] = useState(false);
  const [isHoveringCrowd, setIsHoveringCrowd] = useState(false);
  const [isHoveringCloud, setIsHoveringCloud] = useState(false);
  const [isHoveringAssets, setIsHoveringAssets] = useState(false);
  const [textColor, setTextColor] = useState("#FFFFFF");
  var {
    mClass,
    nClass,
    cClass,
    slogo,
    hbtnClass,
    blueArrow,
    headerClass,
  } = props;
  const gaEventTracker = useAnalyticsEventTracker("Menu Bar");
  let userLogin = localStorage.hasOwnProperty("login-user") ? true : false;
  let userName = localStorage.hasOwnProperty("login-user")
    ? JSON.parse(localStorage["login-user"]).name
    : "";
  let currentLicenseId = localStorage.hasOwnProperty("login-user")
    ? JSON.parse(localStorage["login-user"]).currentLicenseId
    : "";
  let accessToken = localStorage.hasOwnProperty("login-user")
    ? JSON.parse(localStorage["login-user"]).access_token
    : "";
  const history = useHistory();
  let userEmail = localStorage.hasOwnProperty("login-user")
    ? JSON.parse(localStorage["login-user"]).userName
    : "";
  let details = localStorage.hasOwnProperty("login-user")
    ? JSON.stringify(JSON.parse(localStorage["login-user"]))
    : null;
  const DOMAIN_URL = process.env.REACT_APP_MANAGEMENT_DOMAIN_NAME;
  let [pathname, setPathName] = useState("");

  const handleMouseHoverProduct = () => {
    setHoverProducts(true);
    setHoverSolutions(false);
    setHoverResources(false);
  };

  const handleMouseHoverSolutions = () => {
    setHoverProducts(false);
    setHoverSolutions(true);
    setHoverResources(false);
  };
  const handleMouseHoverResources = () => {
    setHoverProducts(false);
    setHoverSolutions(false);
    setHoverResources(true);
  };
  const handleMouseLeave = () => {
    setHoverProducts(false);
    setHoverSolutions(false);
    setHoverResources(false);
  };

  useEffect(() => {
    const pathname = window.location.pathname;
    setPathName(pathname);
  }, []);

  let [appPath, setApppath] = useState("");

  useEffect(() => {
    if (userLogin) {
      if (verifyLicenseStatus(JSON.parse(localStorage["login-user"]))) {
        setApppath(
          `${DOMAIN_URL}/projectmenu/project?from="website"&data=${details}`
        );
      } else {
        setApppath(
          `${DOMAIN_URL}/licenses/fireflink-license?from="website"&data=${details}`
        );
      }
    }
  }, [userLogin, DOMAIN_URL, details]);

  var { mClass, nClass, cClass, slogo, hbtnClass, blueArrow } = props;
  const FIREFLINK_PLATFORM_URL = process.env.REACT_APP_FIREFLINK_PLATFORM_URL;

  const handleScroll = () => {
    const scrollPosition = window.scrollY;

    if (scrollPosition > 1) {
      setTextColor("#434DB8");
    } else {
      setTextColor("#FFFFFF");
    }
  };

  function SignOut() {
    let payload = {
      userEmail,
      currentLicenseId,
      // accessToken,
    };
    signOutReq(payload)
      .then((response) => {
        if (response.data.status === "Success") {
          localStorage.removeItem("login-user");
          history.push(`/`);
          window.location.reload();
        }
      })
      .catch(() => {
        console.log(Error);
      });
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handlePlatformClickScroll = () => {
    const element = document.getElementById("platform_assets");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleDomainClickScroll = () => {
    const element = document.getElementById("domain_assets");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleTemplateClickScroll = () => {
    const element = document.getElementById("template_store");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleMouseOverPlatform = () => {
    setIsHoveringPlatform(true);
    setIsHoveringFinder(false);
    setIsHoveringCrowd(false);
    setIsHoveringCloud(false);
    setIsHoveringAssets(false);
    setIsloading(false);
  };
  const handleMouseOverFinder = () => {
    setIsHoveringFinder(true);
    setIsHoveringPlatform(false);
    setIsHoveringCrowd(false);
    setIsHoveringCloud(false);
    setIsHoveringAssets(false);
    setIsloading(false);
  };

  const handleMouseOverCrowd = () => {
    setIsHoveringCrowd(true);
    setIsHoveringFinder(false);
    setIsHoveringPlatform(false);
    setIsHoveringCloud(false);
    setIsHoveringAssets(false);
    setIsloading(false);
  };
  const handleMouseOverCloud = () => {
    setIsHoveringCloud(true);
    setIsHoveringCrowd(false);
    setIsHoveringFinder(false);
    setIsHoveringPlatform(false);
    setIsHoveringAssets(false);
    setIsloading(false);
  };
  const handleMouseOverAssets = () => {
    setIsHoveringAssets(true);
    setIsHoveringCloud(false);
    setIsHoveringCrowd(false);
    setIsHoveringFinder(false);
    setIsHoveringPlatform(false);
    setIsloading(false);
    setIsloading(false);
  };

  const handleMouseOutPlatform = () => {
    setIsHoveringPlatform(false);
  };
  const handleMouseOutFinder = () => {
    setIsHoveringFinder(false);
  };
  const handleMouseOutCrowd = () => {
    setIsHoveringCrowd(false);
  };
  const handleMouseOutCloud = () => {
    setIsHoveringCloud(false);
  };
  const handleMouseOutAssets = () => {
    setIsHoveringAssets(false);
  };

  const [menu, setMenu] = React.useState(true);
  const [menu1, setMenu1] = useState(true);
  const [menu2, setMenu2] = useState(true);

  const toggleNavbar = () => {
    setMenu(!menu);
    setMenu1(true);
    setMenu2(true);
  };
  const toggleNavbar1 = () => {
    setMenu1(!menu1);
    setMenu(true);
    setMenu2(true);
  };
  const toggleNavbar2 = () => {
    setMenu2(!menu2);
    setMenu1(true);
    setMenu(true);
  };

  const classOne = menu
    ? "collapse navbar-collapse mean-menu"
    : "collapse navbar-collapse show";
  const classTwo = menu
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";

  const classOne1 = menu1
    ? "collapse navbar-collapse mean-menu"
    : "collapse navbar-collapse show";
  const classTwo1 = menu1
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";

  const classOne2 = menu2 ? "collapse mean-menu" : "collapse show";
  const classTwo2 = menu2
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";

  return (
    <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
      <header className={headerClass ? headerClass : "header_area"}>
        <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
          <div className={`container ${cClass}`}>
            <Link className={`navbar-brand ${slogo}`} to="/">
              <div className="logo_beta">Beta</div>
              <img
                className="mt-10"
                src={FireFlinkAssetWhite}
                alt="FireFlink Assets white Logo"
              />
              <img
                className="mt-10"
                src={FireFlinkAsset}
                alt="FireFlink Assets  logo"
              />
            </Link>

            <div className={userLogin ? "d-flex res_menu" : "w_auto_res"}>
              {userLogin ? (
                <>
                  <button
                    onClick={toggleNavbar2}
                    className={classTwo2}
                    type="button"
                    // data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    // aria-controls="navbarSupportedContent"
                    // aria-expanded="false"
                    // aria-label="Toggle navigation"
                  >
                    <div className="profile_icon">
                      <Avatar className="profile_icon_sign">
                        {userName.split("")[0].toUpperCase()}
                      </Avatar>
                    </div>
                  </button>
                  <div className={classOne2}>
                    <div className="profile_popup_res uptri5">
                      <div className="d-flex pt-3 pl-3">
                        <div>
                          <Avatar className="profile_icon_sign">
                            {userName.split("")[0].toUpperCase()}
                          </Avatar>
                        </div>
                        <div className="ml-2">
                          <p className="profile_name">{userName}</p>
                          <a className="profile_email" href="">
                            {userEmail}
                          </a>
                        </div>
                      </div>
                      <div className="profile_line mt-2"></div>
                      <p
                        className="sign_out ml-20 mt-2"
                        onClick={() => {
                          SignOut();
                        }}
                      >
                        Sign Out
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              <button
                onClick={toggleNavbar1}
                className={classTwo1}
                type="button"
                // data-toggle="collapse"
                data-target="#navbarSupportedContent"
                // aria-controls="navbarSupportedContent"
                // aria-expanded="false"
                // aria-label="Toggle navigation"
              >
                <div className="my-prod">
                  <img
                    src={require("../img/home/dot-grid.svg").default}
                    alt="dot grid"
                  />
                </div>
              </button>

              <button
                onClick={toggleNavbar}
                className={classTwo}
                type="button"
                // data-toggle="collapse"
                data-target="#navbarSupportedContent"
                // aria-controls="navbarSupportedContent"
                // aria-expanded="false"
                // aria-label="Toggle navigation"
              >
                <span
                  className={
                    userLogin ? "menu_toggle_res menu_toggle" : "menu_toggle"
                  }
                >
                  <span
                    className={headerClass ? "hamburger whiteNav" : "hamburger"}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                  <span
                    className={
                      headerClass
                        ? "hamburger-cross whiteNav"
                        : "hamburger-cross"
                    }
                    onClick={() => {
                      setMenu(true);
                    }}
                  >
                    <span></span>
                    <span></span>
                  </span>
                </span>
              </button>
            </div>

            <div className={classOne} id="navbarSupportedContent">
              <div className="Mview">
                <DropDownMenu />
              </div>
              <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                <li
                  className="dropdown  submenu nav-item"
                  onMouseEnter={handleMouseHoverProduct}
                  onMouseLeave={handleMouseLeave}
                >
                  <Link
                    to="./"
                    title="Products"
                    className={
                      hoverProducts
                        ? "dropdown-toggle nav-link prodtexthover"
                        : "dropdown-toggle nav-link "
                    }
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Products
                    <img
                      className="arrow"
                      src={
                        hoverProducts
                          ? OrangeDownarrow
                          : window.scrollY > 1
                          ? bluearrow
                          : arrow
                      }
                      alt="arrow"
                    />
                  </Link>

                  <ul
                    className={
                      userLogin
                        ? "nav nav-tabs ml-90 dropdown-menu"
                        : "nav nav-tabs ml-90 dropdown-menu"
                    }
                    id="myTab"
                    role="tablist"
                  >
                    {/* <div className="submenutab"> */}

                    <li class="inline-li nav-item submenuhead">
                      <a
                        className={
                          isLoading || isHoveringPlatform ? "prodcolor" : ""
                        }
                        onMouseOver={handleMouseOverPlatform}
                        // onMouseOut={handleMouseOutPlatform}
                        href="/fireflink-platform"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        FireFlink Platform
                      </a>
                      <div
                        className={
                          isLoading || isHoveringPlatform
                            ? "active_line"
                            : "d-none"
                        }
                      ></div>
                    </li>

                    <li class="inline-li ml-90 nav-item submenuhead">
                      <a
                        className={isHoveringFinder ? "prodcolor" : ""}
                        onMouseOver={handleMouseOverFinder}
                        // onMouseOut={handleMouseOutFinder}
                        href="/fireflink-finder"
                        // href={`/fireflink-finder?product=Fireflink-Finder`}
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        FireFlink Finder
                      </a>
                      <div
                        className={isHoveringFinder ? "active_line" : "d-none"}
                      ></div>
                    </li>
                    <li class="inline-li ml-90 nav-item submenuhead">
                      <a
                        className={isHoveringCloud ? "prodcolor" : ""}
                        onMouseOver={handleMouseOverCloud}
                        // onMouseOut={handleMouseOutCloud}
                        href="/fireflink-cloud"
                        role="tab"
                        aria-controls="crowd"
                        aria-selected="true"
                      >
                        FireFlink Cloud
                      </a>
                      <div
                        className={isHoveringCloud ? "active_line" : "d-none"}
                      ></div>
                    </li>
                    <li class="inline-li ml-90 nav-item submenuhead">
                      <div className="beta">Beta</div>
                      <a
                        className={isHoveringAssets ? "prodcolor" : ""}
                        onMouseOver={handleMouseOverAssets}
                        // onMouseOut={handleMouseOutAssets}
                        href="/fireflink-assets"
                        role="tab"
                        aria-controls="assets"
                        aria-selected="true"
                      >
                        FireFlink Assets
                      </a>
                      <div
                        className={isHoveringAssets ? "active_line" : "d-none"}
                      ></div>
                    </li>

                    <div className="bottomline"></div>
                    <div class="tab-content" id="myTabContent">
                      {isLoading && (
                        <div
                          class="tab-pane fade show active"
                          id="platform"
                          role="tabpanel"
                          aria-labelledby="platform-tab"
                        >
                          <div className="row mb_30">
                            <div className="col-lg-4">
                              <div className="hovebox">
                                <div className="submenulist">
                                  <div>
                                    <img
                                      src={WebAutomation}
                                      alt="Web automation testing"
                                    />
                                  </div>
                                  <div className="submenutext">
                                    <Link
                                      to="/fireflink-platform/web-automation-testing"
                                      exact
                                      className="item"
                                    >
                                      <h3 className="prodcolor">
                                        Web Automation Testing
                                      </h3>
                                    </Link>
                                    <p>Rapid Web Testing Platform</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="submenulist">
                                <div>
                                  <img
                                    src={VisualTesting}
                                    alt="visual testing"
                                  />
                                </div>
                                <div className="submenutext">
                                  <NavLink
                                    to="/fireflink-platform/visual-testing"
                                    exact
                                    className="item"
                                  >
                                    <h3>Visual Testing</h3>
                                  </NavLink>
                                  <p className="black">
                                    Performance Test for Optimized UX
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="submenulist">
                                <div>
                                  <img
                                    src={ManualTest}
                                    alt="manual test case management"
                                  />
                                </div>
                                <div className="submenutext">
                                  <Link
                                    to="/fireflink-platform/manual-test-case-management"
                                    exact
                                    className="item"
                                  >
                                    <h3>Manual Test Case Management</h3>
                                  </Link>
                                  <p className="black">
                                    Convenient Manual Test Execution
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row ">
                            <div className="col-lg-4">
                              <div className="submenulist">
                                <div>
                                  <img
                                    src={MobileAutomation}
                                    alt="Mobile automation testing"
                                  />
                                </div>
                                <div className="submenutext">
                                  <Link
                                    to="/fireflink-platform/mobile-app-automation-testing"
                                    exact
                                    className="item"
                                  >
                                    <h3>Mobile Automation Testing</h3>
                                  </Link>
                                  <p className="black">
                                    Native & Hybrid App Testing{" "}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="submenulist">
                                <div>
                                  <img
                                    src={UiTest}
                                    alt="Ui performance automation testing"
                                  />
                                </div>
                                <div className="submenutext">
                                  <NavLink
                                    to="/fireflink-platform/ui-performance-testing"
                                    exact
                                    className="item"
                                  >
                                    <h3>UI Performance Testing</h3>
                                  </NavLink>
                                  <p className="black">
                                    Finest App "Feel" with UI Testing
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="submenulist">
                                <div>
                                  <img src={Defect} alt="Defect management" />
                                </div>
                                <div className="submenutext">
                                  <Link
                                    to="/fireflink-platform/defect-management"
                                    exact
                                    className="item"
                                  >
                                    <h3>Defect Management</h3>
                                  </Link>
                                  <p className="black">
                                    Custom Templates for Defect Management
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-30">
                            <div className="col-lg-4">
                              <div className="submenulist">
                                <div>
                                  <img
                                    src={ApiAutomation}
                                    alt="Api automation testing"
                                  />
                                </div>
                                <div className="submenutext">
                                  <Link
                                    to="/fireflink-platform/api-automation-testing"
                                    exact
                                    className="item"
                                  >
                                    <h3>API Automation Testing</h3>
                                  </Link>
                                  <p className="black">
                                    For Enhanced API Test Coverage
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="submenulist">
                                <div>
                                  <img
                                    className="ai_img_menu"
                                    src={Ai}
                                    alt="Ai"
                                  />
                                </div>
                                <div className="submenutext">
                                  <Link
                                    to="ai-automation-testing-tool"
                                    exact
                                    className="item"
                                  >
                                    <h3>AI Powered Automation Testing</h3>
                                  </Link>
                                  <p className="black">
                                    Dive into the world of AI driven testing
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4"></div>
                          </div>
                        </div>
                      )}
                      {isHoveringPlatform && (
                        <div
                          class="tab-pane fade show active"
                          id="platform"
                          role="tabpanel"
                          aria-labelledby="platform-tab"
                        >
                          <div className="row mb_30">
                            <div className="col-lg-4">
                              <div className="hovebox">
                                <div className="submenulist">
                                  <div>
                                    <img
                                      src={WebAutomation}
                                      alt="Web automation testing"
                                    />
                                  </div>
                                  <div className="submenutext">
                                    <Link
                                      to="/fireflink-platform/web-automation-testing"
                                      exact
                                      className="item"
                                    >
                                      <h3 className="prodcolor">
                                        Web Automation Testing
                                      </h3>
                                    </Link>
                                    <p>Rapid Web Testing Platform</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="submenulist">
                                <div>
                                  <img
                                    src={VisualTesting}
                                    alt="Visual Testing"
                                  />
                                </div>
                                <div className="submenutext">
                                  <NavLink
                                    to="/fireflink-platform/visual-testing"
                                    exact
                                    className="item"
                                  >
                                    <h3>Visual Testing</h3>
                                  </NavLink>
                                  <p className="black">
                                    Performance Test for Optimized UX
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="submenulist">
                                <div>
                                  <img
                                    src={ManualTest}
                                    alt="manual test case management"
                                  />
                                </div>
                                <div className="submenutext">
                                  <Link
                                    to="/fireflink-platform/manual-test-case-management"
                                    exact
                                    className="item"
                                  >
                                    <h3>Manual Test Case Management</h3>
                                  </Link>
                                  <p className="black">
                                    Convenient Manual Test Execution
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row ">
                            <div className="col-lg-4">
                              <div className="submenulist">
                                <div>
                                  <img
                                    src={MobileAutomation}
                                    alt="Mobile automation testing"
                                  />
                                </div>
                                <div className="submenutext">
                                  <Link
                                    to="/fireflink-platform/mobile-app-automation-testing"
                                    exact
                                    className="item"
                                  >
                                    <h3>Mobile Automation Testing</h3>
                                  </Link>
                                  <p className="black">
                                    Native & Hybrid App Testing{" "}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="submenulist">
                                <div>
                                  <img
                                    src={UiTest}
                                    alt="Ui performance automation testing"
                                  />
                                </div>
                                <div className="submenutext">
                                  <NavLink
                                    to="/fireflink-platform/ui-performance-testing"
                                    exact
                                    className="item"
                                  >
                                    <h3>UI Performance Testing</h3>
                                  </NavLink>
                                  <p className="black">
                                    Finest App "Feel" with UI Testing
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="submenulist">
                                <div>
                                  <img src={Defect} alt="Defect management" />
                                </div>
                                <div className="submenutext">
                                  <Link
                                    to="/fireflink-platform/defect-management"
                                    exact
                                    className="item"
                                  >
                                    <h3>Defect Management</h3>
                                  </Link>
                                  <p className="black">
                                    Custom Templates for Defect Management
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-30">
                            <div className="col-lg-4">
                              <div className="submenulist">
                                <div>
                                  <img
                                    src={
                                      require("../img/home/platform-icon/api-testing.svg")
                                        .default
                                    }
                                    alt="Api testing"
                                  />
                                </div>
                                <div className="submenutext">
                                  <Link
                                    to="/fireflink-platform/api-automation-testing"
                                    exact
                                    className="item"
                                  >
                                    <h3>API Automation Testing</h3>
                                  </Link>
                                  <p className="black">
                                    For Enhanced API Test Coverage
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4"></div>
                            <div className="col-lg-4"></div>
                          </div>
                        </div>
                      )}
                      {isHoveringFinder && (
                        <div class="tab-content" id="myTabContent">
                          <div className="row">
                            <div className="col-lg-4">
                              <div className="submenulist">
                                <div>
                                  <img
                                    src={WebLocatorFinder}
                                    alt="Automation Web locator finder"
                                  />
                                </div>
                                <div className="submenutext">
                                  <Link
                                    to="/fireflink-finder/web-locator-finder"
                                    exact
                                    className="item"
                                  >
                                    <h3>Web Locator Finder</h3>
                                  </Link>
                                  <p className="black">
                                    Hassle-free tool for optimized locators
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="submenulist">
                                <div>
                                  <img
                                    src={MsLocator}
                                    alt="Automation Ms dynamics locator finder"
                                  />
                                </div>
                                <div className="submenutext">
                                  <Link
                                    to="/fireflink-finder/msdynamics-locator-finder"
                                    exact
                                    className="item"
                                  >
                                    <h3>MS Dynamics Locator Finder</h3>
                                  </Link>
                                  <p className="black">
                                    MS Dynamic Apps UI Element Locator
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="submenulist">
                                <div>
                                  <img
                                    src={SalesForceLocator}
                                    alt="Automation Salesforce locator finder"
                                  />
                                </div>
                                <div className="submenutext">
                                  <Link
                                    to="/fireflink-finder/salesforce-locator-finder"
                                    exact
                                    className="item"
                                  >
                                    <h3>Salesforce Locator Finder</h3>
                                  </Link>
                                  <p className="black">
                                    Salesforce-exclusive element finder
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4">
                              <div className="submenulist">
                                <div>
                                  <img
                                    src={MobileLocator}
                                    alt="Automation Mobile locator finder"
                                  />
                                </div>
                                <div className="submenutext">
                                  <Link
                                    to="/fireflink-finder/mobile-app-locator-finder"
                                    exact
                                    className="item"
                                  >
                                    <h3>Mobile Locator Finder</h3>
                                  </Link>
                                  <p className="black">
                                    Synchronous Android/iOS element finder
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-4"></div>
                          </div>
                        </div>
                      )}
                      {isHoveringCrowd && (
                        <div class="tab-content" id="myTabContent">
                          <div className="row">
                            <div className="col-lg-4">
                              <div className="submenulist">
                                <div>
                                  <img
                                    src={
                                      require("../img/home/web-crowd.svg")
                                        .default
                                    }
                                    alt="Web crowd Automation testing"
                                  />
                                </div>
                                <div className="submenutext">
                                  <Link
                                    to="/fireflink-crowd/#web_crowd"
                                    exact
                                    className="item"
                                  >
                                    <h3>Web Crowd Testing</h3>
                                  </Link>
                                  <p className="black">
                                    Comprehensive Web Crowd-Sourced Testing
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="submenulist">
                                <div>
                                  <img
                                    src={
                                      require("../img/home/mobile-crowd.svg")
                                        .default
                                    }
                                    alt="Mobile crowd Automation testing"
                                  />
                                </div>
                                <div className="submenutext">
                                  <Link
                                    to="/fireflink-crowd/#mobile_crowd"
                                    exact
                                    className="item"
                                  >
                                    <h3
                                      // onClick={handlemobilecrowdClickScroll}
                                      to="/#mobile_crowd"
                                    >
                                      Mobile Crowd Testing
                                    </h3>
                                  </Link>
                                  <p className="black">
                                    Massive Crowd-Sourced Mobile Testing
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="submenulist">
                                <div>
                                  <img
                                    src={
                                      require("../img/home/web-mobile-crowd.svg")
                                        .default
                                    }
                                    alt="web and mobile crowd Automation testing"
                                  />
                                </div>
                                <div className="submenutext">
                                  <Link
                                    to="/fireflink-crowd/#webnmob_crowd"
                                    exact
                                    className="item"
                                  >
                                    <h3
                                      // onClick={handlewebnmobClickScroll}
                                      to="/#webnmob_crowd"
                                    >
                                      Web and Mobile Crowd Testing
                                    </h3>
                                  </Link>
                                  <p className="black">
                                    Crowd sourced web & mobile app testing
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {isHoveringCloud && (
                        <div class="tab-content" id="myTabContent">
                          <div className="row mb_30">
                            <div className="col-lg-4">
                              <div className="submenulist">
                                <div>
                                  <img src={CloudMobile} alt="Cloud Mobile" />
                                </div>
                                <div className="submenutext">
                                  <Link
                                    to="/fireflink-cloud/#cloud-mobile"
                                    exact
                                    className="item"
                                  >
                                    <h3 className="prodcolor">Cloud Mobile</h3>
                                  </Link>
                                  <p className="black">
                                    Test Concurrently across 1000s of Devices
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="submenulist">
                                <div>
                                  <img src={CloudWeb} alt="Cloud Web" />
                                </div>
                                <div className="submenutext">
                                  <Link
                                    to="/fireflink-cloud/#cloud-web"
                                    exact
                                    className="item"
                                  >
                                    <h3>Cloud Web</h3>
                                  </Link>
                                  <p className="black">
                                    Universal Compatibility with Browsers and
                                    OSes
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="submenulist">
                                <div>
                                  <img
                                    src={CloudLive}
                                    alt="Cloud Live Testing"
                                  />
                                </div>
                                <div className="submenutext">
                                  <Link
                                    to="/fireflink-cloud/#cloud-live"
                                    exact
                                    className="item"
                                  >
                                    <h3>Cloud Live Testing</h3>
                                  </Link>
                                  <p className="black">
                                    Live Tests with Minimal Efforts across
                                    Environments
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {isHoveringAssets && (
                        <div class="tab-content" id="myTabContent">
                          <div className="row mb_30">
                            <div className="col-lg-4">
                              <div className="submenulist">
                                <div>
                                  <img
                                    src={PlatformAssets}
                                    alt="Platform assets"
                                  />
                                </div>
                                <div className="submenutext">
                                  <Link to="/fireflink-assets/#platform_assets">
                                    <h3
                                      onClick={handlePlatformClickScroll}
                                      to="/#platform_assets"
                                      className="prodcolor"
                                    >
                                      Platform Assets
                                    </h3>
                                  </Link>
                                  <p className="black">
                                    Accelerate Business Platform Application
                                    Testing
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="submenulist">
                                <div>
                                  <img src={DomainAssets} alt="Domain Assets" />
                                </div>
                                <div className="submenutext">
                                  <Link to="/fireflink-assets/#domain_assets">
                                    <h3
                                      onClick={handleDomainClickScroll}
                                      to="/#domain_assets"
                                    >
                                      Domain Assets
                                    </h3>
                                  </Link>
                                  <p className="black">
                                    Off-The-Shelf Test Cases for any Domain
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="submenulist">
                                <div>
                                  <img
                                    src={TemplateStore}
                                    alt="Templates Store"
                                  />
                                </div>
                                <div className="submenutext">
                                  <Link to="/fireflink-assets/#template_store">
                                    <h3
                                      onClick={handleTemplateClickScroll}
                                      to="/#template_store"
                                    >
                                      Templates Store
                                    </h3>
                                  </Link>
                                  <p className="black">
                                    Buy and Sell Templates With Asset Store
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </ul>
                </li>

                <li
                  className="dropdown submenu  nav-item"
                  onMouseEnter={handleMouseHoverSolutions}
                  onMouseLeave={handleMouseLeave}
                >
                  <Link
                    to="./"
                    title="Solutions"
                    className={
                      hoverSolutions
                        ? "dropdown-toggle nav-link prodtexthover"
                        : "dropdown-toggle nav-link"
                    }
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Solutions
                    <img
                      className="arrow"
                      src={
                        hoverSolutions
                          ? OrangeDownarrow
                          : window.scrollY > 1
                          ? bluearrow
                          : arrow
                      }
                      alt="arrow"
                    />
                  </Link>

                  <ul
                    role="menu"
                    className={
                      userLogin
                        ? "ml-100 dropdown-menu"
                        : "ml-100 dropdown-menu"
                    }
                  >
                    <div className="row mt_30 mb_30">
                      <div className="col-lg-4">
                        <div className="hovebox">
                          <div className="submenulist">
                            <div>
                              <img src={SalesForce} alt="salesforce" />
                            </div>
                            <div className="submenutext">
                              <NavLink
                                to="/solution/salesforce-automation-testing"
                                exact
                                className="item"
                              >
                                <h3 className="prodcolor">Salesforce</h3>
                              </NavLink>
                              <p>End to End Salesforce testing ensured</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="submenulist">
                          <div>
                            <img src={MsDynamics} alt="MS Dynamics" />
                          </div>
                          <div className="submenutext">
                            <NavLink
                              to="/solution/msdynamics-automation-testing"
                              exact
                              className="item"
                            >
                              <h3>MS Dynamics</h3>
                            </NavLink>
                            <p className="black">
                              Complex system testing handled consistently
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="submenulist">
                          <div>
                            <img src={SapAutomation} alt="sap" />
                          </div>
                          <div className="submenutext">
                            <NavLink
                              to="/solution/sap-automation-testing"
                              exact
                              className="item"
                            >
                              <h3>SAP</h3>
                            </NavLink>
                            <p className="black">
                              Off the shelf assets for SAP
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb_30">
                      <div className="col-lg-4">
                        <div className="submenulist">
                          <div>
                            <img src={Oracle} alt="Oracle" />
                          </div>
                          <div className="submenutext">
                            <NavLink
                              to="/solution/oracle-automation-testing"
                              exact
                              className="item"
                            >
                              <h3>Oracle </h3>
                            </NavLink>
                            <p className="black">
                              A unified solution for quality test outcome{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="submenulist">
                          <div>
                            <img src={ServiceNow} alt="Service Now" />
                          </div>
                          <div className="submenutext">
                            <NavLink
                              to="/solution/service-now-automation-testing"
                              exact
                              className="item"
                            >
                              <h3>Service Now</h3>
                            </NavLink>
                            <p className="black">
                              Rapid agile adaptability for your ServiceNow
                              applications
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="submenulist">
                          <div>
                            <img src={WorkDayAutomation} alt="Workday" />
                          </div>
                          <div className="submenutext">
                            <NavLink
                              to="/solution/workday-automation-testing"
                              exact
                              className="item"
                            >
                              <h3>Workday</h3>
                            </NavLink>
                            <p className="black">
                              Commit to the depth of test coverage of your
                              workday based app’s
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-lg-4">
                        <div className="submenulist">
                          <div>
                            <img src={PeopleSoft} alt="PeopleSoft" />
                          </div>
                          <div className="submenutext">
                            <NavLink
                              to="/solution/peoplesoft-automation-testing"
                              exact
                              className="item"
                            >
                              <h3>PeopleSoft </h3>
                            </NavLink>
                            <p className="black">
                              Exclusive assets library for PeopleSoft platform
                              testing
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ul>
                </li>

                <li className="nav-item">
                  <NavLink
                    title="Pricing"
                    className="nav-link"
                    to="/fireflink-platform-pricing"
                  >
                    Pricing
                  </NavLink>
                </li>
                <li
                  className="dropdown submenu  nav-item"
                  onMouseEnter={handleMouseHoverResources}
                  onMouseLeave={handleMouseLeave}
                >
                  <Link
                    to="./"
                    title="Resources"
                    className={
                      hoverResources
                        ? "dropdown-toggle nav-link prodtexthover"
                        : "dropdown-toggle nav-link"
                    }
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Resources
                    <img
                      className="arrow"
                      src={
                        hoverResources
                          ? OrangeDownarrow
                          : window.scrollY > 1
                          ? bluearrow
                          : arrow
                      }
                      alt="arrow"
                    />
                  </Link>

                  <ul
                    role="menu"
                    className={
                      userLogin ? "mr-90 dropdown-menu" : "mr-90 dropdown-menu"
                    }
                  >
                    <div className="row mt_30 mb_30">
                      <div className="col-lg-4">
                        <div className="hovebox">
                          <div className="submenulist">
                            <div>
                              <img src={Academy} alt="academy" />
                            </div>
                            <div className="submenutext">
                              <NavLink
                                to="/fireflink-academy"
                                exact
                                className="item"
                              >
                                <h3>Academy</h3>
                              </NavLink>
                              <p className="black">
                                Your Gateway to Master Proficiency in FireFlink
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="hovebox">
                          <div className="submenulist">
                            <div>
                              <img src={VideoTutorials} alt="video tutorials" />
                            </div>
                            <div className="submenutext">
                              <NavLink
                                to="/video-tutorial"
                                exact
                                className="item"
                              >
                                <h3>Video Tutorials</h3>
                              </NavLink>
                              <p className="black">
                                Simplified and Engaging Video Courses{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="submenulist">
                          <div>
                            <img src={Documentation} alt="Documentation" />
                          </div>
                          <div className="submenutext">
                            <NavLink to="/documentation" exact className="item">
                              <h3>Documentation</h3>
                            </NavLink>
                            <p className="black">
                              The Complete FireFlink Platform User Guide
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-lg-4">
                        <div className="submenulist">
                          <div>
                            <img src={CaseStudy} alt="Case studies" />
                          </div>
                          <div className="submenutext">
                            <NavLink to="/case-studies" exact className="item">
                              <h3>Case Studies</h3>
                            </NavLink>
                            <p className="black">
                              Insightful Software Testing Case Studies
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="submenulist">
                          <div>
                            <img src={ReleaseNotes} alt="Release notes" />
                          </div>
                          <div className="submenutext">
                            <NavLink
                              to="/release-notes/latest-version"
                              exact
                              className="item"
                            >
                              <h3>Release Notes</h3>
                            </NavLink>
                            <p className="black">
                              Latest Enhancements and Feature Updates
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="submenulist">
                          <div>
                            <img src={Blogs} alt="blogs" />
                          </div>
                          <div className="submenutext">
                            <NavLink to="/blogs" exact className="item">
                              <h3 className="prodcolor">Blogs</h3>
                            </NavLink>
                            <p>Fascinating Blogs on Software Testing</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-30">
                      <div className="col-lg-4">
                        <div className="submenulist">
                          <div>
                            <img src={Support} alt="Support" />
                          </div>
                          <div className="submenutext">
                            <h3>
                              {" "}
                              <a
                                target="_blank"
                                href="http://support.fireflink.com"
                              >
                                {" "}
                                Support{" "}
                              </a>{" "}
                            </h3>
                            {/* </Link> */}
                            <p className="black">
                              Have a concern? Reach out to us now
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="submenulist">
                          <div>
                            <img src={PreRequites} alt="PreRequites" />
                          </div>
                          <div className="submenutext">
                            <NavLink
                              to="/prerequisites/cloud-version"
                              exact
                              className="item"
                            >
                              <h3>Prerequisites</h3>
                            </NavLink>
                            <p className="black">
                              System requirements for FireFlink Platform Client
                              and Server setup
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ul>
                </li>
              </ul>

              {userLogin ? (
                <>
                  <ul
                    className={`navbar-nav menu ml-4 mr-3 ${nClass}`}
                    id="my-prod-menu"
                  >
                    <li className="dropdown  submenu nav-item ">
                      <div className="my-prod-signin">
                        <h3 className="my-prod-menu-txt">My Products</h3>
                        <div>
                          <img
                            src={require("../img/home/dot-grid.svg").default}
                            alt="dot grid"
                          />
                        </div>
                      </div>
                      <ul
                        class="nav nav-tabs navbar-light dropdown-menu uptri3"
                        id="myTab"
                        role="tablist"
                      >
                        <li class="nav-item submenuhead">
                          <a
                            className={
                              isLoading || isHoveringPlatform ? "prodcolor" : ""
                            }
                            onMouseOver={handleMouseOverPlatform}
                            // onMouseOut={handleMouseOutPlatform}
                            href={appPath}
                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                            target="_blank"
                          >
                            <img
                              className="mt_15"
                              src={
                                require("../img/home/fireflink-platform.svg")
                                  .default
                              }
                              alt="FireFlink Platform"
                            />
                          </a>
                          <h3 className="my_prod_txt">
                            A high-speed test automation platform
                          </h3>
                          <div className="dashed_border mt_15"></div>
                        </li>

                        <li class="nav-item submenuhead">
                          <div>
                            <div
                              className="cursorPointer"
                              onClick={() => {
                                openDeepLink(
                                  "openfireflinkclient://",
                                  "/fireflink-finder/#download",
                                  history
                                );
                              }}
                            >
                              <img
                                src={
                                  require("../img/home/fireflink-finder.svg")
                                    .default
                                }
                                alt="FireFlink Finder"
                              />
                            </div>
                            <h3 className="my_prod_txt">
                              All-in-one tool for locating UI elements!
                            </h3>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>

                  <ul
                    className={`navbar-nav menu  ${nClass}`}
                    id="my-profile-menu"
                  >
                    <li className="dropdown  submenu nav-item ">
                      <Avatar className="profile_icon">
                        {userName?.split("")[0].toUpperCase()}
                      </Avatar>
                      <ul
                        class="nav nav-tabs navbar-light dropdown-menu uptri4"
                        id="myTab"
                        role="tablist"
                      >
                        <div className="d-flex pt-3 pl-3">
                          <div>
                            <Avatar className="profile_icon">
                              {userName?.split("")[0].toUpperCase()}
                            </Avatar>
                          </div>
                          <div className="ml-2">
                            <p className="profile_name">{userName}</p>
                            <a className="profile_email" href="">
                              {userEmail}
                            </a>
                          </div>
                        </div>
                        <div className="profile_line mt-3"></div>
                        <p
                          className="sign_out ml-20 mt-2"
                          onClick={() => {
                            SignOut();
                          }}
                        >
                          Sign Out
                        </p>
                      </ul>
                    </li>
                  </ul>
                </>
              ) : (
                <>
                  <ul className={`navbar-nav menu ml-4 ${nClass}`}>
                    <GetDemoNavButton />
                  </ul>
                  <ul
                    className={`navbar-nav menu ml-4 ${nClass}`}
                    id="my-prod-menu"
                  >
                    <li className="dropdown  submenu nav-item ">
                      <div className="my-prod">
                        <img
                          src={require("../img/home/dot-grid.svg").default}
                          alt="dot grid"
                        />
                      </div>
                      <ul
                        class="nav nav-tabs navbar-light dropdown-menu uptri3"
                        id="myTab"
                        role="tablist"
                      >
                        <li class="nav-item submenuhead">
                          <a
                            className={
                              isLoading || isHoveringPlatform ? "prodcolor" : ""
                            }
                            onMouseOver={handleMouseOverPlatform}
                            // onMouseOut={handleMouseOutPlatform}

                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                            href="/signin?product=fireflink-platform"
                            target="_blank"
                          >
                            <img
                              className="mt_15"
                              src={
                                require("../img/home/fireflink-platform.svg")
                                  .default
                              }
                              alt="FireFlink Platform"
                            />
                          </a>
                          <h3 className="my_prod_txt">
                            A high-speed test automation platform
                          </h3>
                          <div className="dashed_border mt_15"></div>
                        </li>

                        <li class="nav-item submenuhead">
                          <div>
                            <a
                              className={isHoveringFinder ? "prodcolor" : ""}
                              onMouseOver={handleMouseOverFinder}
                              role="tab"
                              aria-controls="home"
                              aria-selected="true"
                              // href="/fireflink-finder"
                              href="/signin?product=Fireflink-Finder"
                              // target="_blank"
                            >
                              <img
                                src={
                                  require("../img/home/fireflink-finder.svg")
                                    .default
                                }
                                alt="FireFlink Finder"
                              />
                            </a>
                            <h3 className="my_prod_txt">
                              All-in-one tool for locating UI elements!
                            </h3>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className={`navbar-nav menu ml-4 ${nClass}`}>
                    <SignInButton
                      path="/signin"
                      isHeader={headerClass ? headerClass : false}
                    />
                  </ul>
                </>
              )}
            </div>
          </div>
        </nav>
      </header>
    </Sticky>
  );
};

export default AssetsNavbar;

import React, { useState, useEffect } from "react";
import Reveal from "react-reveal/Reveal";
import { Link } from "react-router-dom";
import fireflinkwhitelogo from "../../img/fireflink-white-logo.png";
import phonecallimg from "../../img/home/phone-call.png";
import emailsupportimg from "../../img/home/email-support.png";
import axios from "axios";
import FloatingDemo from "../Common/FloatingDemo";
import ModalComp from "../Common/ModalComp";

const FooterSecurity = (props) => {
  const [countryData, setCountry] = useState({});
  const [show, setShow] = useState(false);
  const [activeKey, setActiveKey] = useState("tab1");
  // const [opened, setOpened] = useState(false);
  let FooterData = props.FooterData;
  // const theme = {
  //   background: "#f5f8fb",
  //   fontFamily: "Poppins, Regular",
  //   headerBgColor: "#EF6C00",
  //   headerFontColor: "#fff",
  //   headerFontSize: "15px",
  //   botBubbleColor: "#EF6C00",
  //   botFontColor: "#fff",
  //   userBubbleColor: "#fff",
  //   userFontColor: "#4a4a4a",
  // };

  // const toggleFloating = () => {

  //   setOpened(!opened);
  // };

  // const handleLinkClick = (url) => {
  //   window.open(url, "_blank");
  // };
  const fetchData = async () => {
    const apiUrl = "https://api.country.is/";
    try {
      const response = await axios.get(apiUrl);
      const responseData = response.data;
      setCountry(responseData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 10000); // 10 minutes in milliseconds

    return () => clearTimeout(timer);
  }, []); // Only runs on initial render

  const handleShow = (key) => {
    setShow(true);
    setActiveKey(key);
  };
  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <footer className="footer_area footer_area h_footer_dark pos_footer_area security_footer_area">
        <ModalComp
          activeKey={activeKey}
          show={show}
          handleClose={handleClose}
        />
        <div className="container">
          <div className="row">
            {FooterData.CompanyWidget.map((widget) => {
              return (
                <Reveal effect="fadeInLeft" duration={500} key={1}>
                  {countryData?.country === "IN" ? (
                    <div className="col-lg-3 col-sm-6">
                      <div
                        className="f_widget dark_widget company_widget"
                        data-wow-delay="0.2s"
                      >
                        <a className="f-logo">
                          <img src={fireflinkwhitelogo} alt="logo" />
                        </a>
                        <h3 className="mt_30">India</h3>
                        <p>
                          4th floor, IndiQube South Mile building, Vijayarangam
                          Layout, Jayanagar, Bengaluru, Karnataka, 560070.
                        </p>
                        <div className="foot_support_call mt_20 d-flex">
                          <img
                            className="mt_6"
                            src={phonecallimg}
                            alt="phone call logo"
                          />
                          <a
                            className="email_contactUs"
                            href="tel:+919900010384"
                          >
                            +91 99000 10384
                          </a>
                        </div>
                        <div className="foot_support_email mt_10 d-flex">
                          <img
                            className="mt_6"
                            src={emailsupportimg}
                            alt="email support logo"
                          />
                          <a
                            className="email_contactUs"
                            href="mailto:info@fireflink.com"
                          >
                            info@fireflink.com
                          </a>
                        </div>

                        <div className="f_widget about-widget displyMobile mt_10">
                          <ul className="list-unstyled f_list">
                            <li>
                              <Link to="/contactus">Contact Us</Link>
                            </li>
                          </ul>
                        </div>

                        <div className="f_social_icon">
                          {FooterData.socialIcon.map((item) => {
                            return (
                              <a href={item.url} key={item.id}>
                                <i className={item.icon}></i>
                              </a>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  ) : countryData.country === "GB" ? (
                    <div className="col-lg-3 col-sm-6">
                      <div
                        className="f_widget dark_widget company_widget"
                        data-wow-delay="0.2s"
                      >
                        <a className="f-logo" href="/">
                          <img src={fireflinkwhitelogo} alt="logo" />
                        </a>
                        <h3 className="mt_30">United Kingdom</h3>
                        <p>10 John Street, London, United Kingdom, WC1N 2EB.</p>
                        <div className="foot_support_call mt_20 d-flex">
                          <img
                            className="mt_6"
                            src={phonecallimg}
                            alt="phone call logo"
                          />

                          <a
                            className="email_contactUs"
                            href="tel:+44 772 358 5361"
                          >
                            +44 772 358 5361
                          </a>
                        </div>

                        <div className="f_widget about-widget displyMobile mt_10">
                          <ul className="list-unstyled f_list">
                            <li>
                              <Link to="/contactus">Contact Us</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="f_social_icon">
                          {FooterData.socialIcon.map((item) => {
                            return (
                              <a href={item.url} key={item.id}>
                                <i className={item.icon}></i>
                              </a>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-lg-3 col-sm-6">
                      <div
                        className="f_widget dark_widget company_widget"
                        data-wow-delay="0.2s"
                      >
                        <a className="f-logo" href="/">
                          <img src={fireflinkwhitelogo} alt="logo" />
                        </a>
                        <h3 className="mt_30">Canada</h3>
                        <p>
                          120 Adelaide Street West, Suite 2500 Toronto, ON, M5H
                          1T1.
                        </p>

                        <div className="foot_support_call mt_20 d-flex">
                          <img
                            className="mt_6"
                            src={phonecallimg}
                            alt="phone call logo"
                          />
                          <a
                            className="email_contactUs"
                            href="tel:+1 888 980 7010"
                          >
                            +1 888 980 7010
                          </a>
                        </div>
                        <h3 className="mt_20">United States of America</h3>
                        <p>
                          99 South Almaden Blvd, Suite 600, San Jose,
                          California, CA 95113
                        </p>
                        <div className="foot_support_call mt_20 d-flex">
                          <img
                            className="mt_6"
                            src={phonecallimg}
                            alt="phone call logo"
                          />
                          <a
                            className="email_contactUs"
                            href="tel:+1 888 980 7010"
                          >
                            +1 888 980 7010
                          </a>
                        </div>

                        <div className="f_widget about-widget displyMobile mt_10">
                          <ul className="list-unstyled f_list">
                            <li>
                              <Link to="/contactus">Contact Us</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="f_social_icon">
                          {FooterData.socialIcon.map((item) => {
                            return (
                              <a href={item.url} key={item.id}>
                                <i className={item.icon}></i>
                              </a>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                </Reveal>
              );
            })}
            {FooterData.Whyfireflink.map((widget) => {
              return (
                <Reveal effect="fadeInLeft" duration={500} key={widget.id}>
                  <div className="col-lg-2 col-sm-6">
                    <div className="f_widget about-widget">
                      <h3 className="mt_60">{widget.title}</h3>
                      <ul className="list-unstyled f_list">
                        {widget.menuItems.map((item) => {
                          return (
                            <li key={item.id}>
                              <Link to={item.url}>{item.text}</Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </Reveal>
              );
            })}
            {FooterData.Platform.map((widget) => {
              return (
                <Reveal effect="fadeInLeft" duration={500} key={widget.id}>
                  <div className="col-lg-3 col-sm-6">
                    <div className="f_widget about-widget">
                      <h3 className="mt_60">{widget.title}</h3>
                      <ul className="list-unstyled f_list">
                        {widget.menuItems.map((item) => {
                          return (
                            <li key={item.id}>
                              <Link to={item.url}>{item.text}</Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </Reveal>
              );
            })}

            {FooterData.Solutions.map((widget) => {
              return (
                <Reveal effect="fadeInLeft" duration={500} key={widget.id}>
                  <div className="col-lg-2 col-sm-6">
                    <div className="f_widget about-widget ml_30">
                      <h3 className="mt_60">{widget.title}</h3>
                      <ul className="list-unstyled f_list">
                        {widget.menuItems.map((item) => {
                          return (
                            <li key={item.id}>
                              <Link to={item.url}>{item.text}</Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </Reveal>
              );
            })}
            {FooterData.Resources.map((widget) => {
              return (
                <Reveal effect="fadeInLeft" duration={500} key={widget.id}>
                  <div className="col-lg-2 col-sm-6">
                    <div className="f_widget about-widget ml_30">
                      <h3 className="mt_60">{widget.title}</h3>
                      <ul className="list-unstyled f_list">
                        {widget.menuItems.map((item) => {
                          return (
                            <li key={item.id}>
                              <Link to={item.url}>{item.text}</Link>
                            </li>
                          );
                        })}
                        <li>
                          <a
                            target="_blank"
                            href="http://support.fireflink.com"
                            rel="noreferrer"
                          >
                            {" "}
                            Support{" "}
                          </a>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </Reveal>
              );
            })}
          </div>
          <div className="footeBottomeContent mt_60">
            <div className="footer_bottom_line"></div>
            <div className="d-flex Tcontent mt_20">
              <p className="">
                Copyright © FireFlink Pvt Ltd. All Rights Reserved
              </p>
              <p>
                <span
                  className="cursor_pointer"
                  onClick={() => {
                    handleShow("tab1");
                  }}
                >
                  Terms of use
                </span>{" "}
                l{" "}
                <span
                  className="cursor_pointer"
                  onClick={() => {
                    handleShow("tab2");
                  }}
                >
                  Privacy policy
                </span>{" "}
                l{" "}
                <span
                  className="cursor_pointer"
                  onClick={() => {
                    handleShow("tab3");
                  }}
                >
                  EULA
                </span>
              </p>
            </div>
          </div>
          <div className="pos_footer_bottom justify-content-between d-flex align-items-end"></div>
        </div>
        {/* <svg className="wave_shap" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.302" fill="rgb(73, 69, 140)" d="M22.990,11.999 C22.990,11.999 -62.334,196.188 102.000,90.998 C236.033,5.204 349.217,18.152 512.000,80.998 C773.380,181.909 898.075,49.752 1051.000,15.998 C1316.369,-42.574 1488.518,78.268 1581.000,112.999 C1783.485,189.036 1942.990,61.999 1942.990,61.999 L1942.990,211.999 L22.990,211.999 L22.990,11.999 Z" />
                </svg> */}
        {/* <div className="App">
      <header className="App-header">
        <Chatbot config={config} actionProvider={ActionProvider} 	    messageParser={MessageParser} />
      </header>
    </div> */}

        {/* <ThemeProvider theme={theme}>
        <ChatBot
          floating={true}
          opened={opened}
          headerTitle="FireFlink Support"
          toggleFloating={toggleFloating}
          height="390px"
          bubbleStyle={{ color: "#000" }}
          bubbleOptionStyle={{
            color: "#000",
            fontSize: "16px",
            padding: "10px",
          }}
          steps={[
            {
              id: "1",
              message: "For which product you need support?",
              trigger: "2",
            },
            {
              id: "2",
              options: [
                { value: 1, label: "FireFlink Platform", trigger: "3" },
                { value: 2, label: "FireFlink Finder", trigger: "4" },
                { value: 3, label: "FireFlink Crowd", trigger: "5" },
                { value: 4, label: "FireFlink Cloud", trigger: "6" },
                { value: 5, label: "FireFlink Assets", trigger: "7" },
              ],
            },
            {
              id: "3",
              message: "Contact platform@fireflink.com",
              trigger: "2",
            },
            {
              id: "4",
              component: (
                <div className="bot_content">
                  {" "}
                  <p>This is an example component </p>
                </div>
              ),
              trigger: "2",
            },
            {
              id: "5",
              message: "Contact crowd@fireflink.com",
              trigger: "2",
            },
            {
              id: "6",
              message: "Contact cloud@fireflink.com",
              trigger: "2",
            },
            {
              id: "7",
              options: [
                {
                  value: "wikipedia",
                  label: "Wikipedia",
                  trigger: () => handleLinkClick("https://www.wikipedia.org"),
                },
                {
                  value: "github",
                  label: "GitHub",
                  trigger: () => handleLinkClick("https://www.github.com"),
                },
              ],
            },
            {
              id: "8",
              message: "What is your name?",
              trigger: "9",
            },
            {
              id: "9",
              user: true,
              trigger: "2",
            },
          ]}
        />
      </ThemeProvider> */}
      </footer>
      {showPopup && <FloatingDemo />}
    </>
  );
};

export default FooterSecurity;
